import React from "react";
import InformationCard from "./InformationCard";
import { faUserDoctor, faComments, faPersonBreastfeeding } from "@fortawesome/free-solid-svg-icons";



import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>¿Qué hacemos?</span>
        </h3>
        <p className="info-description">
          Nuestro equipo multidisciplinario ofrece <span className="text-green">evaluación</span> exhaustiva, <span className="text-green">terapia </span>
          especializada en <span className="text-green">psicología</span> infantil y para adolescentes, <span className="text-green">intervención </span>
          psicopedagógica, así como <span className="text-green">evaluación</span> y tratamiento de trastornos del habla y
          lenguaje. Creemos en una intervención temprana y un enfoque transdisciplinario
          para promover el bienestar y <span className="text-green">desarrollo </span> óptimo de cada niño y adolescente.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Terapia personalizada:"
          description="Adaptamos cada intervención específicamente a las necesidades individuales de cada niño, garantizando así un impacto óptimo de la terapia."
          icon={faPersonBreastfeeding}
        />


        <InformationCard
          title="Integración de especialidades"
          description="La estrecha colaboración entre
          nuestros profesionales asegura una gestión eficiente del proceso
          terapéutico. Esto se traduce en intervenciones más efectivas y
          resultados más rápidos."
          icon={faUserDoctor}
        />

        <InformationCard
          title="Comunicación continua"
          description="Mantenemos una comunicación constante
          y fluida entre los diferentes especialistas para asegurar que todos
          estén al tanto de los avances y desafíos de cada niño. Esto facilita
          realizar ajustes inmediatos en el enfoque terapéutico según sea
          necesario."
          icon={faComments}
        />
      </div>
    </div>
  );
}

export default Info;
