import React from "react";
import Doctor from "../Assets/doctor-book-appointment.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/BookAppointment.css";
function BookAppointment() {
  const handleBookAppointmentClick = () => {
    // Reemplaza este enlace con tu enlace personalizado para reservar una cita
    window.open("https://wa.me/+593987653460/?text=Hola,%20me%20gustaría%20agendar%20una%20cita.", "_blank");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={Doctor} alt="Doctor Group" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Dra Marizol Jimenez Luzón</span>
        </h3>
        <p className="ba-description">
          He trabajado con niños en edad escolar, adolescentes y he brindado apoyo a padres y maestros. Cada experiencia ha sido enriquecedora y ha contribuido a mi crecimiento personal y profesional. Mi enfoque se centra en desarrollar estrategias personalizadas y efectivas para abordar las necesidades específicas de cada niño y adolescente, convencida del poder transformador del apoyo psicopedagógico y psicológico en la salud mental.
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#d0e200" }} /> Magister en Educación con mención en Intervención Psicopedagógica.
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#d0e200" }} /> Premio "Nous" a Excelencia Educativa otorgado por la fundación Integración de América Latina.
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#d0e200" }} /> Psicorehabilitadora y Educadora Especial.
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#d0e200" }} /> Ponente de varias Universidades del país y Latino America en temas del neurodesarrollo, y trantornos del comportamiento.
        </p>

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleBookAppointmentClick}
        >
          <FontAwesomeIcon icon={faCalendarCheck} />  Agenda una cita
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;
