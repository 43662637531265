export const frequentlyAskedQuestions = [
  {
    "question": "¿Cuándo llevar a mi hijo a terapia psicológica?",
    "answer": "Si notas cambios drásticos en el comportamiento de tu hijo, como retraimiento social, irritabilidad extrema, cambios repentinos en el rendimiento académico o problemas de conducta recurrentes."
  },
  {
    "question": "¿Hasta cuándo mi hijo debe hablar?",
    "answer": "Entre los 3 y 4 años, muchos niños pueden expresar ideas más complejas y participar en conversaciones más significativas."
  },
  {
    "question": "¿Cómo puede afectar a mi hijo el retraso de su neurodesarrollo?",
    "answer": "El retraso en el neurodesarrollo puede afectar a los niños de diferentes maneras, dependiendo de la naturaleza y la gravedad del retraso como: Lenguaje y la comunicación, habilidades motoras, desarrollo cognitivo, habilidades sociales y emocionales."
  }
];
