import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Hero.css";
import Carousel from "./Carousel";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const handleBookAppointmentClick = () => {
    // Reemplaza este enlace con tu número de WhatsApp y mensaje personalizado si lo deseas
    window.open("https://wa.me/+593987653460/?text=Hola,%20me%20gustaría%20agendar%20una%20cita.", "_blank");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <h2 className="text-title">
            Centro de Psicología Integral y Terapia
          </h2>
          <p className="text-descritpion">
            Somos expertos en transtornos del habla y del lenguaje, psicologia psicopedagogía y transtornos del lenguaje.
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Agenda una cita
          </button>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>1000+</p>
              <p>Pacientes Atendidos</p>
            </div>  

            <div className="text-stats-container">
              <p>15+</p>
              <p>Años de Experiencia</p>
              
            </div>
            <div className="text-stats-container">
              <p>4</p>
              <p>Profesionales Capacitados</p>
            </div>
          </div>
        </div>
        <div className="carousel_img">
          <Carousel />
        </div>
      </div>

      <div
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
