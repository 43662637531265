import React, { useEffect } from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";
import Imagen1 from "../Assets/gallerydiv/po4.jpg";
import PoImage2 from "../Assets/gallerydiv/po2.jpg";
import PoImage3 from "../Assets/gallerydiv/po3.jpg";
import PoImage4 from "../Assets/gallerydiv/po.jpg";
import PoImage5 from "../Assets/gallerydiv/po5.jpeg";
import PoImage6 from "../Assets/gallerydiv/po6.jpeg";
import PoImage7 from "../Assets/gallerydiv/po7.jpg";
import PoImage8 from "../Assets/gallerydiv/po9.jpg";
import PoImage9 from "../Assets/gallerydiv/po10.jpg";
import PoImage10 from "../Assets/gallerydiv/po11.jpg";
import PoImage11 from "../Assets/gallerydiv/po12.jpg";
import PoImage12 from "../Assets/gallerydiv/po13.jpg";
import PoImage14 from "../Assets/gallerydiv/po14.jpeg";
import gsap from "gsap";

function About() {
  // Lista de trastornos
  const trastornosaprendizaje = [
    "Dificultades de la lectura (Dislexia)",
    "Dificultades de la expresion escrita (Disgrafía)",
    "Dificultades de las matemáticas escrita (Discalculia)"
  ];
  const trastornoanimo = [
    "Trastorno de Ansiedad Generalizada (TAG)",
    "Trastorno de Ansiedad por Separación",
    "Trastorno de Estrés Postraumático (TEPT)",
    "Trastorno Depresivo Mayor (Depresión Mayor)",
  ];
  const trastornoConducta = [
    "Trastorno Disruptivo del Control de los Impulsos",
    "Trastorno Negativista Desafiante",
  ];
  const trastornoNeurodesarrollo = [
    "Trastorno del Espectro Autista (TEA)",
    "Trastorno por Déficit de Atención e Hiperactividad (TDAH)",
  ];
  const trastornolenguaje = [
    "Dislalia",
    "Disfemia",
    "Trastorno Fonológico",
    "Retraso del habla"
  ];
  useEffect(() => {
    
    const slideWrapper = document.querySelector(".slider-wrapper");
    const slides = document.querySelectorAll(".slide");
    let target = 0;
    let current = 0;
    let ease = 0.07;
    let maxScroll = slideWrapper.offsetWidth - window.innerWidth;
    let pageHeight = document.body.clientHeight - window.innerHeight;

    function lerp(start, end, factor) {
      return start + (end - start) * factor;
    }

    function updateScaleAndPosition() {
      slides.forEach((slide) => {
        const rect = slide.getBoundingClientRect();
        const centerPosition = (rect.left + rect.right) / 2;
        const distanceFromCenter = centerPosition - window.innerWidth / 2;
        let scale, offsetX;
        if (distanceFromCenter > 0) {
          scale = Math.min(1.75, 1 + distanceFromCenter / window.innerWidth);
          offsetX = (scale - 1) * 300;
        } else {
          scale = Math.max(
            0.5,
            1 - Math.abs(distanceFromCenter) / window.innerWidth
          );
          offsetX = 0;
        }
        gsap.set(slide, { scale: scale, x: offsetX });
      });
    }

    function update() {
      current = lerp(current, target, ease);
      gsap.set(".slider-wrapper", {
        x: -current
      });
      updateScaleAndPosition();
      requestAnimationFrame(update);
    }

    window.addEventListener("resize", () => {
      maxScroll = slideWrapper.offsetWidth - window.innerWidth;
      pageHeight = document.body.clientHeight - window.innerHeight;
    });

    window.addEventListener("wheel", (e) => {
      const deltaY = e.deltaY * 5; // Ajusta el factor multiplicativo para aumentar la velocidad
      target += deltaY;
      target = Math.max(0, target);
      target = Math.min(maxScroll, target);
    });

    window.addEventListener("scroll", () => {
      const scrollFraction = window.scrollY / pageHeight;
      target = scrollFraction * maxScroll;
    });

    update();
  }, []);


  return (
    <div className="about-section" id="about">
      <div className="about-content">
        <div className="about-image-content">
          <img src={Doctor} alt="Doctor Group" className="about-image1" />
        </div>

        <div className="about-text-content">

          <h3 className="about-title">
            <span>¿Qué nos diferencia?</span>
          </h3>
          <p className="about-description">
            Nuestra atención integral y coordinada, brindando apoyo constante en
            terapias individuales y grupales, manteniendo una comunicación
            efectiva entre la familia, el colegio y los centros médicos.
          </p>

          <h4 className="about-text-title">Nuestras Soluciones</h4>

          {/* Mapeo de la lista de trastornos */}
          <SolutionStep
            title="Trastorno del aprendizaje"
            description={trastornosaprendizaje.map((trastorno, index) => (
              <div key={index}>&#8226; {trastorno}</div>
            ))}
          />

          <SolutionStep
            title="Trastornos del estado de ánimo"
            description={trastornoanimo.map((trastorno, index) => (
              <div key={index}>&#8226; {trastorno}</div>
            ))}
          />

          <SolutionStep
            title="Trastornos de la conducta"
            description={trastornoConducta.map((trastorno, index) => (
              <div key={index}>&#8226; {trastorno}</div>
            ))}

          />
          <SolutionStep
            title="Trastornos del Neurodesarrollo"
            description={trastornoNeurodesarrollo.map((trastorno, index) => (
              <div key={index}>&#8226; {trastorno}</div>
            ))}

          />
          <SolutionStep
            title="Trastornos del Lenguaje"
            description={trastornolenguaje.map((trastorno, index) => (
              <div key={index}>&#8226; {trastorno}</div>
            ))}

          />
        </div>
      </div>

      <div className="slider">
        <div className="slider-wrapper">
          <div className="slide">
            <img src={Imagen1} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage2} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage3} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage4} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage5} alt="Po" />

          </div>
          <div className="slide">
            <img src={PoImage6} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage7} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage8} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage9} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage10} alt="Po" />

          </div>
          <div className="slide">
            <img src={PoImage11} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage12} alt="Po" />
          </div>
          <div className="slide">
            <img src={PoImage14} alt="Po" />
          </div>

        </div>
      </div>
    </div>

  );
}

export default About;
