import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/profile-1.png";
import profile2 from "../Assets/profile-2.png";
import profile3 from "../Assets/profile-3.png";
import profile4 from "../Assets/profile-4.png";
import profile5 from "../Assets/profile-5.png";
import "../Styles/Doctors.css";


function Doctors() {
  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Nuestros Profesionales</span>
        </h3>

        <p className="dt-description">
        Somos un equipo multidisciplinario especializado en la salud mental, con un enfoque de trabajo integrador. Nuestra misión principal es ayudar a resolver problemas psicológicos, educativos, de lenguaje y clínicos en las personas.
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile2}
          name="Psi. Camila Cortez Bonilla"
          title="Psicoterapeuta Cognitiva Conductual y EFT"
          reviews="Quito"
        />
        <DoctorCard
          img={profile4}
          name="Psi. Jessica Conza Masache"
          title="Psicologia General"
          reviews="Loja"
        />
        <DoctorCard
          img={profile5}
          name="Psi. Melisa Maurad León"
          title="Psicologa General y certificacion en Educación Parvularia"
          reviews="Loja"
        />
        <DoctorCard
          img={profile1}
          name="Dra. Marizol Jimenéz Luzón"
          title="Especialista en Psicopedagogía y trantornos del Habla y Lenguaje"
          reviews="Loja-Quito"
        />
         <DoctorCard
          img={profile3}
          name="Psi. Maria Daniela Astudillo"
          title="Maestria en Psicologia Clinica"
          reviews="Loja"
        />
      </div>
    </div>
  );
}

export default Doctors;
