import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF,faInstagram,faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import "../Styles/Footer.css";
function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Psicotelcon <span className="ft-sign"></span>
            </p>
            <p className="ft-description">
              Hable con nuestros especialistas en línea y obtenga asesoramiento gratuito en línea a tu alcance.
            </p>
            <p className="ft-description">
              Sucre entre Miguel Riofrío y Azuay (Edificio Medicos Consultorio 207-208) 
            </p>
          </div>

        </div>


        <div className="ft-list" id="contact">
          <p className="ft-list-title">Contactanos</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:psicotel.psico@gmail.com">psicotel.psico@gmail.com</a>
            </li>
            <li>
              <a href="tel:+593 99254 5567">+593 992545567</a>
            </li>
            <li>
              <a href="tel:+593 987653460">+593 987653460</a>
            </li>
          </ul>
        </div>
        <div className="socialmedia">
        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.facebook.com/PsicotelconTerapias"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
             <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/psicotelcon_ecuador"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram } />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/+593987653460"
              title="WhatsApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp } />
            </a>
          </li>
          <li>
            <a
              href="https://www.google.com/maps/place/PSICOTELCON/@-3.9992104,-79.2033268,18.15z/data=!4m6!3m5!1s0x91cb37cf09e2786f:0x565e8315325cb495!8m2!3d-3.9999298!4d-79.2031458!16s%2Fg%2F11q801r_dh?entry=ttu"
              title="Direccion"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faMapLocationDot } />
            </a>
          </li>
        </ul>
      </div>
      </div>
      

      <div className="ft-copyright">
        <p>Diseñado por Phi-φ. Todos los derechos reservados.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://linkedin.com/in/abrahamdefacto/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </li>

          

        </ul>
      </div>
    </div>
  );
}

export default Footer;
