import React, { useState } from "react";
import { frequentlyAskedQuestions } from "../Scripts/frequentlyAskedQuestions"; // Importar el archivo de preguntas frecuentes
import "../Styles/Reviews.css";

function Reviews() {
  const [questionIndex, setQuestionIndex] = useState(0); // Cambiar el nombre de la variable
  const questionsLength = frequentlyAskedQuestions.length; // Cambiar el nombre de la variable

  // Retroceder a la pregunta anterior
  const backBtnClick = () => {
    setQuestionIndex((prevIndex) =>
      prevIndex === 0 ? questionsLength - 1 : prevIndex - 1
    );
  };

  // Ir a la siguiente pregunta
  const frontBtnClick = () => {
    setQuestionIndex((prevIndex) =>
      prevIndex === questionsLength - 1 ? 0 : prevIndex + 1
    );
  };

  // Obtener la pregunta actual y su respuesta
  const currentQuestion = frequentlyAskedQuestions[questionIndex]; // Cambiar el nombre de la variable
  const { question, answer } = currentQuestion;

  return (
    <div className="review-section" id="reviews">
      <div className="rw-text-content">
        <p className="rw-text-desc">{question}</p> {/* Mostrar la pregunta */}
        
        <p className="rw-text-format">
          <span className="rw-text-quote1">''</span>
          <span className="rw-review">{answer}</span> {/* Mostrar la respuesta */}
          <span className="rw-text-quote2">''</span>
        </p>

        <div className="rw-authors">
          
          <div className="rw-btns">
            <button
              className="rw-next-btn"
              type="button"
              onClick={backBtnClick}
            >
              ←
            </button>
            <button
              className="rw-next-btn"
              type="button"
              onClick={frontBtnClick}
            >
              →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
