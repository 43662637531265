import React from "react";
import styled from "styled-components";
import carousel1 from "../Assets/carousel-hero/Carousel1.jpg";
import carousel2 from "../Assets/carousel-hero/Carousel2.jpg";
import carousel3 from "../Assets/carousel-hero/Carousel3.jpg";


const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative
  margin-top: 0;
`;

const Slide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      slides: [carousel1, carousel2, carousel3], // Agrega todas tus imágenes aquí
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.showNextSlide, 5000); // Cambia de imagen cada 5 segundos
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  showNextSlide = () => {
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === this.state.slides.length - 1
          ? 0
          : prevState.currentIndex + 1,
    }));
  };

  render() {
    const { slides, currentIndex } = this.state;
    return (
      <Container>
        {slides.map((slide, index) => (
          <Slide
            key={index}
            src={slide}
            alt={`Carousel slide ${index}`}
            style={{
              transform: `translateX(${-(currentIndex * 100)}%)`,
              transition: "transform ease-out 0.45s",
            }}
          />
        ))}
      </Container>
    );
  }
}

export default Carousel;

